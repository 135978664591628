import { Tooltip } from '@mui/material';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import ReactSelect from 'react-select';
import { CheckCircle, HelpOutline } from '@material-ui/icons';

import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { useHistory } from 'react-router-dom';
import { useGetMethod, usePostMethod } from '../../../Hooks';

import {
  customSwaltAlert,
  customSwaltAlertAsistencial,
  formatteDocument,
  loader,
} from '../../../helpers';

import arrayRight from '../../../assets/img/icons/Arrow-gray.svg';
import alertCircleRed from '../../../assets/img/icons/alertCircleRed.svg';
import arrowRight from '../../../assets/img/icons/arrowDerechaCalendario.svg';
import icon_search from '../../../assets/img/icons/ordSearchClearBlue.svg';
import pdf from '../../../assets/img/icons/pdfClearBlueIcon.svg';

import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';
import { Text } from '../../../components/UI/atoms';
import DepositComponent from '../../../components/DepositComponent/DepositComponent';

import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import {
  formatNumberWithoutDecimals,
  getLocaleSeparators,
} from '../../../helpers/numberFormatting';
import './SurgeryPayment.scss';

const localeSeparators = getLocaleSeparators();

const programmedHeaders = [
  { title: 'No. Cirugía', className: 'px-2 text-center' },
  { title: 'Servicio', className: 'px-2 text-center' },
  { title: 'Empresa cliente', className: 'px-2 text-center' },
  { title: 'Contrato', className: 'px-2 text-center' },
  { title: 'Valor servicio', className: 'px-2 text-end' },
  { title: 'Valor paciente', className: 'px-2 text-end' },
  { title: 'Pagado', className: 'px-2 text-center' },
  { title: '', className: 'px-2' },
];

const priceHeader = [
  { title: 'No. Cirugía', className: 'px-2' },
  { title: 'Cuenta', className: 'px-2 text-center' },
  { title: 'Valor servicio', className: 'px-2 text-end' },
  { title: 'Descuento', className: 'px-2 text-end' },
  { title: 'Pago paciente', className: 'px-2 text-end' },
];

export default function SurgeryPayment() {
  const { colors } = useTheme();

  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const userId = store.loginReducer.user_data.id;
  const siteId = store.userReducer.site;
  const resolutions = store.userReducer.resolutions;

  const [selectedSurgery, setSelectedSurgery] = useState(null);
  const [selectedResolution, setSelectedResolution] = useState({
    label: 'Seleccionar...',
    value: null,
  });
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [filters, setFilters] = useState({
    search: '',
    eaccount: eaccount,
  });
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();
  const [currentDeposits, setCurrentDeposits] = useState([]);

  const { load: savePaymentsLoader, trigger: savePayment } = usePostMethod();

  const {
    results: paymentHistory,
    load: paymentHistoryLoader,
    trigger: getPaymentHistory,
  } = useGetMethod();

  const { trigger: getPaymentPdf, load: paymentPdfLoader } = useGetMethod();
  const { trigger: getCashClosingConfig, load: loadCashClosingConfig } = useGetMethod();

  const {
    results: listPatients,
    load: listPatientLoader,
    trigger: getListPatient,
  } = useGetMethod();

  const surgeryPaymentState = {
    group: 'Portal Asistencial',
    catDetail: {
      title: 'Módulo de Admisión',
      url: '/admision/inicio',
      home_img: store.loginReducer.newCategories
        .find(e => e.description === 'Portal Asistencial')
        ?.data.find(e => e.url === 'admision')?.home_img,
    },
  };

  useEffect(() => {
    if (resolutions && resolutions[0]) {
      const option = resolutions[0];
      setSelectedResolution({
        label: option.name,
        value: option.id,
      });
    }
  }, [resolutions]);

  useEffect(() => {
    if (selectedSurgery) {
      void getPaymentHistory({
        url: `/surgery/payments/${selectedSurgery.id}`,
        token: token,
      });
    }
  }, [eaccount, filters, getPaymentHistory, selectedSurgery, token]);

  useEffect(
    () => {
      getCashClosingConfig({
        url: `/medical/admissions/cashClosingConfig/`,
        objFilters: { userId, siteId, eaccount },
        token: token,
      }).then(res => {
        if (res?.success) {
          if (res?.results?.hasPendingCashClosing) {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: 'Para poder seguir realizando abonos, es necesario que realice un cierre parcial',
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
            }).then(() => {
              history.push({
                pathname: '/admision/inicio',
                state: surgeryPaymentState,
              });
            });
          }
          if (res?.results?.cashClosingStatus === 'closed') {
            return customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: `No se encontró un cierre diario abierto asociado a la sede y usuario enviados`,
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
            }).then(() => {
              history.push({
                pathname: '/admision/inicio',
                state: surgeryPaymentState,
              });
            });
          }
        } else {
          customSwaltAlertAsistencial({
            icon: 'warning',
            title: `Intenta de nuevo`,
            text: res?.message,
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
          });
        }
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    void getListPatient({
      url: '/surgery/payments/patients',
      objFilters: filters,
      token: token,
    });
  }, [eaccount, filters, getListPatient, token]);

  const resolutionsOptions = useMemo(() => {
    const options = [
      { label: 'Seleccionar...', value: null },
      ...(resolutions?.map(res => ({
        label: res.name,
        value: res.id,
      })) ?? []),
    ];
    return options;
  }, [resolutions]);

  const calculateTotalService = useMemo(() => {
    if (selectedPatient) {
      return selectedPatient.surgeries?.reduce((acc, el) => {
        return acc + Number(el.servicePrice);
      }, 0);
    }
    return 0;
  }, [selectedPatient]);

  const calculateTotalPatient = useMemo(() => {
    if (selectedPatient) {
      return selectedPatient.surgeries?.reduce((acc, el) => {
        return acc + Number(el.patientPrice);
      }, 0);
    }
    return 0;
  }, [selectedPatient]);

  const calculateTotalPaid = useMemo(() => {
    if (paymentHistory?.data?.[0] && selectedSurgery) {
      return paymentHistory.data[0]?.payments.reduce((acc, el) =>  el.status.value !== "cancelled" ? acc + Number(el.totalPaid) : acc, 0);
    }
    return 0;
  }, [paymentHistory.data, selectedSurgery]);

  const totalBalance = useMemo(() => {
    return Number(selectedSurgery?.value || 0) >= Number(selectedPatient?.totalBalance)
      ? Number(selectedPatient?.totalBalance)
      : Number(selectedSurgery?.value || 0);
  }, [selectedPatient?.totalBalance, selectedSurgery?.value]);

  const onSearchPatient = event => {
    event.preventDefault();
    const { searchValue } = event.target;

    setSearchValue(searchValue.value);
    setSelectedPatient(null);

    setFilters({
      ...filters,
      search: searchValue.value,
    });
  };

  const onChangeValue = (surgery, value = '') => {
    setSelectedSurgery({ ...surgery, value: value });
  };

  const onDownloadPaymentPdf = payment => {
    getPaymentPdf({
      url: `/surgery/payments/${selectedSurgery.id}/payment-pdf`,
      objFilters: {
        eaccount,
        userId,
        siteId,
        resolutionId: selectedResolution.value,
        sequence: payment.sequence,
      },
      token: token,
      succesAction: results => {
        let treatPdf = results?.data?.base64?.replace(/^b'/gm, '').replace(/=*'$/gm, '');
        let linkSource = `data:application/pdf;base64,${treatPdf}`;
        let downloadLink = document.createElement('a');
        let fileName = `factura_de_pago_${payment.sequence}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      },

      doAfterException: results => {
        customSwaltAlertAsistencial({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const onChangeDeposit = deposits => {
    setCurrentDeposits(deposits);
  };

  const onCancelPayment = () => {
    setSelectedPatient(null);
    setSelectedSurgery(null);
  };

  const handleSubmit = () => {
    if (!selectedResolution.value) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: 'Asegúrese de seleccionar una resolución',
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    const deposits = currentDeposits.map(dep => ({
      id: dep?.depositTypeId,
      amount: dep?.amount,
      pendingAmount: dep?.pendingAmount && dep.returnedChange ? 0 : dep.pendingAmount ?? 0,
      donation: dep?.donate ? dep?.donationValue : undefined,
      bankId: dep?.bankId ?? undefined,
      franchiseId: dep?.franchiseId ?? undefined,
      transactionCode: dep?.referenceNumber,
    }));

    if (deposits.some(dep => !dep.amount)) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: 'Asegúrese de llenar los campos de pago.',
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    const payload = {
      userId: userId,
      eaccount: eaccount,
      siteId: siteId,
      surgeries: [{ id: selectedSurgery.id, paymentValue: +selectedSurgery.value }],
      deposits: deposits,
      resolutionId: selectedResolution.value,
    };

    savePayment({
      token: token,
      body: payload,
      method: 'POST',
      url: '/surgery/payments',
      succesAction: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Pagado exitosamente',
          text: results.message,
          confirmButtonText: 'Aceptar',
        })
          .then(async response => {
            if (response.isConfirmed || response.isDismissed) {
              const patientListResponse = await getListPatient({
                url: '/surgery/payments/patients',
                objFilters: {
                  eaccount,
                  search: selectedPatient.patient.document,
                },
                token: token,
              });

              if (patientListResponse?.data) {
                const patient = patientListResponse.data[0];
                setSelectedPatient(patient);

                const surgery = patient.surgeries.find(surg => surg.id === selectedSurgery.id);
                if (surgery) setSelectedSurgery({ ...surgery, value: '' });
              }
            }
          })
          .finally(() => {
            getCashClosingConfig({
              url: `/medical/admissions/cashClosingConfig/`,
              objFilters: { userId: userId, siteId, eaccount },
              token: token,
            });
          });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const renderList = () => {
    let tempList = [];
    if (listPatients.data && Array.isArray(listPatients.data)) {
      listPatients.data.forEach((item, index) => {
        tempList.push(
          <>
            <div
              className={tableStyles.app_container_card_list_patient}
              key={index}
              onClick={() => {
                setSelectedPatient(item);
                setSelectedSurgery(null);
              }}
            >
              <div className='d-flex flex-column m-0'>
                <span className={tableStyles.app_title_list}>
                  {item.patient?.firstName} {item.patient?.middleName} {item.patient?.lastName}{' '}
                  {item.patient?.secondSurname}
                </span>
                <span className={tableStyles.app_text_list}>
                  {item.patient.documentType.name} {formatteDocument(item.patient.document).format}
                </span>
              </div>
              <img
                src={arrayRight}
                alt='right'
                width='10'
                className={`cursorPointer ${tableStyles.appColorArrow}`}
              />
            </div>
          </>,
        );
      });
    }
    return tempList;
  };

  const renderSidebar = () => {
    const hasExistingPatient = !searchValue.length && listPatients.data?.length < 1;

    return (
      <aside className='patient-sidebar'>
        <div
          className={`${tableStyles.ordDarkBlueText} fs-2 fw-bold p-4`}
          style={{ lineHeight: 1 }}
        >
          Abono de cirugía
        </div>

        <div className={`${tableStyles.app_back_search} mx-4 mb-4`}>
          <span className={tableStyles.app_title_search}>Buscar</span>
          <form onSubmit={onSearchPatient} className={tableStyles.app_container_icon_search}>
            <input
              className={`${tableStyles.app_input_search} ps-2`}
              type='text'
              placeholder='Escribir...'
              name='searchValue'
              disabled={hasExistingPatient}
            />
            <button type='submit'>
              <img className='hoverPointer' width='27px' alt='search' src={icon_search} />
            </button>
          </form>
        </div>

        {!listPatientLoader && hasExistingPatient ? (
          <div className={tableStyles.app_container_alert_patient}>
            <img src={alertCircleRed} alt='alert-cicle' />
            <div className={tableStyles.app_alert_patient}>
              <span>No hay pacientes para mostrar</span>
            </div>
          </div>
        ) : !listPatientLoader && searchValue.length && listPatients.data?.length < 1 ? (
          <div className={tableStyles.app_container_alert_patient}>
            <img src={alertCircleRed} alt='alert-cicle' />
            <div className={tableStyles.app_alert_patient}>
              <span>Paciente no encontrado</span>
            </div>
          </div>
        ) : (
          <>
            <div>
              <div className={tableStyles.app_container_list_patient} style={{ height: '45vh' }}>
                {renderList()}
              </div>
              {listPatients.data?.length > 0 && (
                <span className={`${tableStyles.app_text_list} px-3 `}>
                  ({listPatients.rowTotal}) resultados
                </span>
              )}
            </div>
          </>
        )}
      </aside>
    );
  };

  const renderPayments = () => {
    return (
      <div className='col-lg-7 pe-3'>
        <div className={`fw-bold ${tableStyles.tlnTextPrimary}`}>Pago</div>
        <OrdTable
          shadow
          headers={priceHeader}
          hasChildren={true}
          oneHeadDarkBlue={false}
          lowerCase={false}
          footer={
            <tr>
              <td colSpan={3} className={`text-end p-2 fw-bold ${tableStyles.ordDarkBlueText}`}>
                Total abono restante
              </td>
              <td className={`text-end p-2 fw-bold ${tableStyles.ordDarkBlueText}`}>
                {selectedSurgery && calculateTotalPaid
                  ? formatNumberWithoutDecimals(
                      Math.floor(selectedSurgery?.patientPrice) - calculateTotalPaid,
                    )
                  : formatNumberWithoutDecimals(Math.floor(selectedSurgery?.patientPrice))}
              </td>
              <td className={`text-end p-2 fw-bold ${tableStyles.ordDarkBlueText}`}>
                {formatNumberWithoutDecimals(
                  isNaN(selectedSurgery.value) ? 0 : selectedSurgery.value,
                )}
              </td>
            </tr>
          }
        >
          {selectedSurgery && (
            <tr key={selectedSurgery.id}>
              <td className='px-2 text-start'>{selectedSurgery.id}</td>
              <td className='px-2'>{selectedSurgery.accountName}</td>
              <td className='px-2 text-end'>
                {formatNumberWithoutDecimals(selectedSurgery.servicePrice)}
              </td>
              <td className='px-2 text-end'>{selectedSurgery.percentageDiscount}%</td>
              <td className='px-2 text-end'>
                <NumberFormat
                  className='form-control form-control-sm ms-auto text-end'
                  allowNegative={false}
                  allowLeadingZeros={false}
                  style={{ width: 100 }}
                  isAllowed={({ floatValue }) => {
                    const value = floatValue ?? 0;
                    if (calculateTotalPaid && selectedSurgery)
                      return value <= selectedSurgery?.patientPrice - calculateTotalPaid;
                    return value <= selectedSurgery?.patientPrice;
                  }}
                  onValueChange={({ value }) => onChangeValue(selectedSurgery, value)}
                  value={selectedSurgery.value}
                  isNumericString={true}
                  thousandSeparator={localeSeparators.groupSeparator}
                  decimalSeparator={localeSeparators.decimalSeparator}
                  format={valueStr => {
                    if (!valueStr) return '';

                    return formatNumberWithoutDecimals(valueStr);
                  }}
                />
              </td>
            </tr>
          )}
        </OrdTable>
      </div>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {(listPatientLoader ||
          savePaymentsLoader ||
          loadCashClosingConfig ||
          paymentHistoryLoader ||
          paymentPdfLoader) &&
          loader}
        <div className='d-flex w-100'>
          {renderSidebar()}

          {!selectedPatient && (
            <section className='flex-grow-1 d-flex align-items-center justify-content-center'>
              <div className='fs-4 text-muted'>No se ha seleccionado ningún paciente.</div>
            </section>
          )}

          {selectedPatient && (
            <div
              className='d-flex flex-column align-items-start flex-grow-1 py-4 px-5'
              style={{ overflowY: 'auto' }}
            >
              <div className={`fw-bold ${tableStyles.tlnTextPrimary} mb-3`}>
                Información del paciente
              </div>

              <div
                className={`${tableStyles.app_back_search} row g-0 w-100`}
                style={{ flexDirection: 'row' }}
              >
                <div className='col-lg-3'>
                  <small className='text-muted'>Paciente</small>
                  <div className={`${tableStyles.ordDarkBlueText} fw-bold`}>
                    {selectedPatient.patient.firstName} {selectedPatient.patient.lastName}
                  </div>
                </div>
                <div className='col-lg-3'>
                  <small className='text-muted'>Identificación</small>
                  <div className={`${tableStyles.ordDarkBlueText} fw-bold`}>
                    {selectedPatient.patient.documentType.name}{' '}
                    {formatteDocument(selectedPatient.patient.document).format}
                  </div>
                </div>
                <div className='col-lg-2'>
                  <small className='text-muted'>Edad</small>
                  <div className={`${tableStyles.ordDarkBlueText} fw-bold`}>
                    {moment().diff(selectedPatient.patient.birthDate, 'years')} años
                  </div>
                </div>
                <div className='col-lg-2'>
                  <small className='text-muted'>Nivel</small>
                  <div className={`${tableStyles.ordDarkBlueText} fw-bold`}>
                    {selectedPatient.surgeries?.find(surgery => surgery?.copaymentRate)
                      ?.copaymentRate ?? '-'}
                  </div>
                </div>
                <div className='col-lg-2'>
                  <small className='text-muted'>Tipo de afiliado</small>
                  <div className={`${tableStyles.ordDarkBlueText} fw-bold`}>
                    {selectedPatient.patient?.affiliationType.name}
                  </div>
                </div>
              </div>

              <div className={`fw-bold ${tableStyles.tlnTextPrimary} mt-3`}>
                Cirugías programadas
              </div>

              <OrdTable
                shadow
                headers={programmedHeaders}
                hasChildren={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
                footer={
                  <tr>
                    <td
                      colSpan={5}
                      className={`text-end p-2 fw-bold ${tableStyles.ordDarkBlueText}`}
                    >
                      Total
                    </td>
                    <td className={`text-end p-2 fw-bold ${tableStyles.ordDarkBlueText}`}>
                      {formatNumberWithoutDecimals(calculateTotalService)}
                    </td>
                    <td className={`text-end p-2 fw-bold ${tableStyles.ordDarkBlueText}`}>
                      {formatNumberWithoutDecimals(calculateTotalPatient)}
                    </td>
                    <td width={40}></td>
                  </tr>
                }
              >
                {selectedPatient.surgeries?.map(surgery => {
                  const rowSelectedStyle = {
                    backgroundColor: selectedSurgery?.id === surgery.id ? '#fcf1e1' : undefined,
                  };
                  return (
                    <tr key={`${surgery.document}_${surgery.id}`}>
                      <td style={rowSelectedStyle} className='px-2'>
                        {surgery.id}
                      </td>
                      <td style={rowSelectedStyle} className='px-2'>
                        {surgery.serviceName}
                      </td>
                      <td style={rowSelectedStyle} className='px-2'>
                        {surgery.companyName}
                      </td>
                      <td style={rowSelectedStyle} className='px-2'>
                        {surgery.contractName}
                      </td>
                      <td style={rowSelectedStyle} className='px-2 text-end'>
                        {formatNumberWithoutDecimals(surgery.servicePrice)}
                      </td>
                      <td style={rowSelectedStyle} className='px-2 text-end'>
                        {formatNumberWithoutDecimals(surgery.patientPrice)}
                      </td>
                      <td style={rowSelectedStyle} className='px-2'>
                        {surgery?.isTotalPaid ? (
                          <Tooltip title='Pagado totalmente' placement='top' arrow>
                            <CheckCircle htmlColor='#83c036' />
                          </Tooltip>
                        ) : (
                          <Tooltip title='Pendiente por pagar' placement='top' arrow>
                            <HelpOutline htmlColor='#f3c682' />
                          </Tooltip>
                        )}
                      </td>
                      <td style={rowSelectedStyle} width={40}>
                        <Tooltip
                          title={surgery?.isTotalPaid ? 'Ver pagos' : 'Realizar abono'}
                          placement='top'
                          arrow
                        >
                          <button onClick={() => setSelectedSurgery(surgery)}>
                            <img width={10} src={arrowRight} alt='' />
                          </button>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </OrdTable>

              <div className='row w-100 g-0'>
                {selectedSurgery &&
                  calculateTotalPaid - Math.floor(Number(selectedSurgery?.patientPrice)) !== 0 &&
                  renderPayments()}
                <div className='col-lg-5'>
                  {selectedSurgery && (
                    <>
                      <div className={`fw-bold ${tableStyles.tlnTextPrimary}`}>
                        Abonos realizados
                      </div>
                      <OrdTable
                        shadow
                        headers={[
                          { title: 'Factura', className: 'px-2 text-center' },
                          { title: 'Fecha', className: 'px-2 text-center' },
                          { title: 'Monto', className: 'text-end px-2' },
                          { title: 'PDF', className: 'text-center px-2' },
                        ]}
                        hasChildren={true}
                        oneHeadDarkBlue={false}
                        lowerCase={false}
                        footer={
                          paymentHistory?.data?.[0]?.payments ? (
                            <tr>
                              <td className={`text-end p-2 fw-bold ${tableStyles.ordDarkBlueText}`}>
                                Total
                              </td>
                              <td
                                colSpan={2}
                                className={`text-end p-2 fw-bold ${tableStyles.ordDarkBlueText}`}
                              >
                                {formatNumberWithoutDecimals(calculateTotalPaid)}
                              </td>
                            </tr>
                          ) : null
                        }
                      >
                        {paymentHistory?.data?.[0]?.payments ? (
                          paymentHistory?.data?.[0].payments.map((payment, i) => (
                            payment?.status?.value==='cancelled' ?
                            (
                              <Tooltip title={payment?.status?.description || '' } placement='top' arrow key={i}>
                                <tr>
                                  <td className='px-2' style={{backgroundColor: '#fe8a8a30'}}>
                                    {`${payment?.resolutionPrefix || ''}${payment?.sequence}`}
                                  </td>
                                  <td className='px-2' style={{backgroundColor: '#fe8a8a30'}}> 
                                    {moment(payment.paymentDate).format('DD/MM/YYYY hh:mm')}
                                  </td>
                                  <td className='px-2 text-end' style={{backgroundColor: '#fe8a8a30'}}>
                                    {formatNumberWithoutDecimals(payment.totalPaid)}
                                  </td>
                                  <td className='px-2 text-center' style={{backgroundColor: '#fe8a8a30'}}>
                                    <img
                                      className='pointer'
                                      src={pdf}
                                      width={15}
                                      onClick={() => onDownloadPaymentPdf(payment)}
                                      alt=''  
                                    />
                                  </td>
                                </tr>
                              </Tooltip>
                            ):(
                              <tr  key={i}>
                                <td className='px-2'>
                                  {`${payment?.resolutionPrefix || ''}${payment?.sequence}`}
                                </td>
                                <td className='px-2'>
                                  {moment(payment.paymentDate).format('DD/MM/YYYY hh:mm')}
                                </td>
                                <td className='px-2 text-end'>
                                  {formatNumberWithoutDecimals(payment.totalPaid)}
                                </td>
                                <td className='px-2 text-center'>
                                  <img
                                    className='pointer'
                                    src={pdf}
                                    width={15}
                                    onClick={() => onDownloadPaymentPdf(payment)}
                                    alt=''  
                                  />
                                </td>
                              </tr>
                            )
                            ))
                        ) : (
                          <tr>
                            <td colSpan={2} className='text-center px-2'>
                              No hay abonos por mostrar
                            </td>
                          </tr>
                        )}
                      </OrdTable>
                    </>
                  )}
                </div>
              </div>
              {selectedSurgery &&
                selectedSurgery.value &&
                calculateTotalPaid - Math.floor(Number(selectedSurgery?.patientPrice)) !== 0 && (
                  <>
                    <div className='d-flex align-items-start'>
                      <Form.Group className='mb-3 text-start' style={{ width: 250 }}>
                        <Text
                          title={'Resolución'}
                          color={colors.ordDarkBlueText}
                          fontSize={'14px'}
                          required
                        />
                        <ReactSelect
                          noOptionsMessage={() => 'No hay datos'}
                          className='text-secondary'
                          placeholder={'Seleccionar...'}
                          onChange={option => setSelectedResolution(option)}
                          styles={ordCustomSelect}
                          options={resolutionsOptions}
                          value={selectedResolution}
                        />
                      </Form.Group>
                      {Number(selectedPatient.totalBalance) > 0 && (
                        <div className='ms-3'>
                          <Text
                            title={'Saldo a favor:'}
                            color={colors.ordDarkBlueText}
                            fontSize={'14px'}
                          />
                          <input
                            type='text'
                            className={`register-inputs`}
                            value={formatNumberWithoutDecimals(
                              Number(selectedPatient.totalBalance) -
                                Number(selectedSurgery.value || 0) <
                                0
                                ? 0
                                : Number(selectedPatient.totalBalance) -
                                    Number(selectedSurgery.value || 0),
                            )}
                            style={{
                              maxHeight: '32px',
                              height: '32px',
                              fontSize: '14px',
                              borderColor: '#a3bad1',
                            }}
                            disabled
                          />
                        </div>
                      )}
                    </div>

                    <div className={`fw-bold ${tableStyles.tlnTextPrimary}`}>Recaudo</div>

                    <DepositComponent
                      payment={selectedSurgery?.value}
                      onChange={onChangeDeposit}
                      walletAmount={
                        Number(selectedPatient.totalBalance) > 0 ? totalBalance : undefined
                      }
                    />

                    <div className='d-flex w-100 mt-4 justify-content-end'>
                      <button
                        className={`mr-2 btn ${tableStyles.tlnBtnPrimaryligth}`}
                        onClick={onCancelPayment}
                      >
                        Cancelar
                      </button>
                      <button className={`${tableStyles.tlnBtnPrimary} btn`} onClick={handleSubmit}>
                        Guardar
                      </button>
                    </div>
                  </>
                )}
            </div>
          )}
        </div>
      </>
    );
  };

  return renderComponent();
}
