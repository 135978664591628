import { Radio, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { components } from 'react-select';

import {
  create_Candidate,
  create_Contract,
  getAnnouncement,
  getSelectsDirCont,
  getVacancyType,
  updateContractById,
} from '../../../actions/contractAction';

import adj from '../../../assets/img/icons/Adjuntar.svg';
import x from '../../../assets/img/icons/Cerrar.svg';
import Agregar from '../../../assets/img/icons/add-check.svg';
import Atras from '../../../assets/img/icons/atras-icon.svg';
import iconCamera from '../../../assets/img/icons/iconCamera.svg';
import iconPrint from '../../../assets/img/icons/iconPrint.svg';
import PdfIcon from '../../../assets/img/icons/pdfIcon.svg';
import settlement from '../../../assets/img/icons/settlement.svg';

import { city_get, province_get } from '../../../actions/locationActions';
import { getUrlContract } from '../../../actions/partnersActions';
import { getSites } from '../../../actions/sitesActions';
import { customSwaltAlert } from '../../../helpers/customSwaltAlert';
import {
  convertBase64,
  isEmptyOrUndefined,
  message,
  validateEmail,
  formatteDocument
} from '../../../helpers';

import ModalNew from '../../Layouts/ModalNew';
import { PdfViewer } from '../../Layouts/PdfViewer';
import SelectComponent from '../../SelectComponent/SelectComponent';

import bankMoveStyle from '../../../TreasuryModule/MovimientosBancarios/bankMoveStyles.module.scss';
import { customSelectNewDark } from '../../Layouts/react-select-custom';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import IndividualStyles from '../../TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Styles from '../Hiring/Contract.module.scss';
import { useGetMethod } from '../../../Hooks';

function CreateContract(props) {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  let history = useHistory();

  let isEditing = props?.isEditing;
  let statusFinalized = props?.information?.status === 'Finalizado';
  function goBack() {
    history.push('/nomina/contratos');
  }
  const [showModal, setShowModal] = useState(false);
  const token = counter.loginReducer.Authorization;
  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'hiring',
  );
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(1);
  const [trigger1, setTrigger1] = useState(1);

  let asterisk = <b style={{ color: '#FF8B00' }}>*</b>;
  const [subData, setSubData] = useState({
    btnYes: true,
  });
  const [subDataCandidate, setSubDataCandidate] = useState({
    btnYes: true,
  });
  const [showContract, setShowContract] = useState(false);

  useEffect(() => {
    Object.keys(props)?.length > 0 && dispatch(getUrlContract(props?.information?.contract_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [data, setData] = useState({
    vacancy_type: 2,
    job_position: props?.information?.job_positionid || undefined,
    job_description: props?.information?.job_description || '',
    contract_type: props?.information?.contract_typeid || undefined,
    start_date: props?.information?.star_date
      ? props.information.star_date.split('/').reverse().join('-')
      : '',
    end_date: props?.information?.end_date
      ? props.information.end_date.split('/').reverse().join('-')
      : '',
    site_id: props?.information?.site_id || undefined,
    site_name: props?.information?.site_name || '',
    site_address: props?.information?.site_address || '',
    base_salary: props?.information?.base_salary || '',
    non_const_income: props?.information?.non_const_income || '',
    candidate: props?.information?.cand_id || undefined,
    doc_number: props?.information?.cand_docnumber || '',
    entity_account: counter.loginReducer.currentAccount.id,
    created_by: counter.loginReducer.user_data.id,
    modality: props?.information?.modality || '',
  });

  useEffect(() => {
    if (!props.show) {
      if (!myPermission?.create) {
        goBack();
      } else {
        dispatch(getVacancyType({}));
        dispatch(
          getSites({
            page: 1,
            perpage: 1000,
            id_account: counter.loginReducer.currentAccount.id,
          }),
        );
        dispatch(
          getAnnouncement({
            entity_account: counter.loginReducer.currentAccount.id,
          }),
        );
        dispatch(
          province_get({
            entity_account: counter.loginReducer.currentAccount.id,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    dispatch(
      getSelectsDirCont({
        entity_account: counter.loginReducer.currentAccount.id,
        status: 'verified',
      }),
    );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (data.contract_type === 1) {
      setData({
        ...data,
        end_date: '',
      });

      if (
        isEmptyOrUndefined(data.job_position) ||
        isEmptyOrUndefined(data.contract_type) ||
        isEmptyOrUndefined(data.site_id) ||
        isEmptyOrUndefined(data.base_salary) ||
        isEmptyOrUndefined(data.candidate)
      ) {
        return setSubData({ ...subData, btnYes: false });
      } else {
        return setSubData({ ...subData, btnYes: true });
      }
    }
    if (data.contract_type !== 1) {
      if (
        !!data.job_position &&
        !!data.contract_type &&
        !!data.start_date &&
        !!data.end_date &&
        !!data.site_id &&
        !!data.base_salary &&
        !!data.candidate
      ) {
        setSubData({ ...subData, btnYes: false });
      } else {
        setSubData({ ...subData, btnYes: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.contract_type]);

  const [candidateData, setCandidateData] = useState({
    full_name: '',
    entity_account: counter.loginReducer.currentAccount.id,
    created_by: counter.loginReducer.user_data.id,
    doc_type: '',
    doc_number: '',
    birthdate: '',
    doc_issued: '',
    email: '',
    occupation: '',
    province_id: '',
    province_name: '',
    city_id: '',
    city_name: '',
    home_address: '',
    description: '',
    mobile: '',
    names: '',
    last_name: '',
    gender: '',
  });

  let optionsGender = [
    { label: 'Seleccionar...', value: '' },
    { label: 'Masculino', value: 'male' },
    { label: 'Femenino', value: 'female' },
  ];
  const [image, setimage] = useState({
    filename: '',
    base64: '',
    support_type: 's3_photo',
    fileUrl: '',
  });
  const [file, setFile] = useState({
    filename: '',
    base64: '',
    support_type: 's3_cv',
    fileUrl: '',
  });

  const {trigger: getSmmlv} = useGetMethod()

  const imageUploadValue = document.querySelector('#imageUpload');

  const handleImagePick = async e => {
    const input_file = e.target.files[0]
    if (e.target.id === 'imageUpload' && !!input_file) {
      if (input_file.size >= 3 * 1024 * 1024){
        return message(
          'warning',
          'Intenta de nuevo',
          'El tamaño de la imagen no puede ser mayor a 3MB',
          undefined,
          true,
        );
      }
      let encode = await convertBase64(input_file);
      let file64 = encode.split(',')[1];
      setimage({
        ...image,
        filename: input_file.name,
        base64: file64,
        fileUrl: URL.createObjectURL(input_file),
      });
    } else if (e.target.id === 'cvUpload' && !!input_file) {
      if (input_file.size >= 3 * 1024 * 1024){
        return message(
          'warning',
          'Intenta de nuevo',
          'El tamaño de la hoja de vida no puede ser mayor a 3MB',
          undefined,
          true,
        );
      }
      let encode = await convertBase64(input_file);
      let file64 = encode.split(',')[1];
      setFile({
        ...file,
        support_type: 's3_cv',
        filename: input_file.name,
        base64: file64,
        fileUrl: URL.createObjectURL(input_file),
      });
    }
  };
  const contractGenerate = async () => {
    if (data.contract_type === 1) {
      setData({
        ...data,
        end_date: '',
      });
      if (
        isEmptyOrUndefined(data.job_position) ||
        isEmptyOrUndefined(data.contract_type) ||
        isEmptyOrUndefined(data.site_id) ||
        isEmptyOrUndefined(data.base_salary) ||
        isEmptyOrUndefined(data.candidate)
      ) {
        return message(
          'warning',
          'Intenta de nuevo',
          'Por favor diligencie todos los campos marcados como obligatorios',
          undefined,
          true,
        );
      }
    }
    if (data.contract_type !== 1) {
      if (
        isEmptyOrUndefined(data.job_position) ||
        isEmptyOrUndefined(data.contract_type) ||
        isEmptyOrUndefined(data.start_date) ||
        isEmptyOrUndefined(data.end_date) ||
        isEmptyOrUndefined(data.site_id) ||
        isEmptyOrUndefined(data.base_salary) ||
        isEmptyOrUndefined(data.candidate)
      ) {
        return message(
          'warning',
          'Intenta de nuevo',
          'Por favor diligencie todos los campos marcados como obligatorios',
          undefined,
          true,
        );
      }
    }

    if (data.contract_type !== 1) {
      if (data.end_date < data.start_date) {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'La fecha de inicio del contrato debe ser menor a la fecha final',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
    }
    const response = await getSmmlv({
      url: '/payroll/params',
      objFilters: {
        eaccount: counter.loginReducer.currentAccount.id,
        _curdate: 1,
        active: 1
      },
      token: counter.loginReducer.Authorization
    })
    if (!response.success) {
      return customSwaltAlert({
        icon: 'error',
        title: response?.title,
        text: "Error de servidor",
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    const SMMLV = response.results.find(x => x.param === 'smmlv')

    if (isEmptyOrUndefined(SMMLV)) {
      return customSwaltAlert({
        icon: 'error',
        title: "No hay parámetros de nómina asignados a la cuenta en sesión",
        text: "Debe parametrizar el salario mínimo legal vigente a la cuenta en sesión",
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    if (data.base_salary > (SMMLV.value * 5)) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Adventencia',
        text: 'El salario base supera cinco (5) veces el salario mensual mínimo legal vigente, ¿Desea continuar?',
        confirmButtonText: 'Sí, continuar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          dispatch(create_Contract({ ...data, vacancy_type: 2 }, () => goBack()));
        }
      })
    } else {
      dispatch(create_Contract({ ...data, vacancy_type: 2 }, () => goBack()));
    }
  };

  const contractUpdate = async () => {
    if (data.contract_type === 1) {
      setData({
        ...data,
        end_date: '',
      });
      if (
        isEmptyOrUndefined(data.job_position) ||
        isEmptyOrUndefined(data.contract_type) ||
        isEmptyOrUndefined(data.site_id) ||
        isEmptyOrUndefined(data.base_salary) ||
        isEmptyOrUndefined(data.candidate)
      ) {
        return message(
          'warning',
          'Intenta de nuevo',
          'Por favor diligencie todos los campos marcados como obligatorios',
          undefined,
          true,
        );
      }
    }
    if (data.contract_type !== 1) {
      if (
        isEmptyOrUndefined(data.job_position) ||
        isEmptyOrUndefined(data.contract_type) ||
        isEmptyOrUndefined(data.start_date) ||
        isEmptyOrUndefined(data.end_date) ||
        isEmptyOrUndefined(data.site_id) ||
        isEmptyOrUndefined(data.base_salary) ||
        isEmptyOrUndefined(data.candidate)
      ) {
        return message(
          'warning',
          'Intenta de nuevo',
          'Por favor diligencie todos los campos marcados como obligatorios',
          undefined,
          true,
        );
      }
    }

    if (data.contract_type !== 1) {
      if (data.end_date < data.start_date) {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'La fecha de inicio del contrato debe ser menor a la fecha final',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
    }
    try {
      setIsLoading(true);
      const result = await updateContractById(data, props?.information?.contract_id, token);
      if (result.success) {
        setIsLoading(false);
        props.setshowDetail({
          show: false,
          isEditing: false,
        });

        return customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha actualizado el contrato: ${result.contract}`,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
      }
    } catch (error) {
      setIsLoading(false);
      message(
        'warning',
        'Intenta de nuevo',
        'Ha ocurrido un error al actualizar el contrato',
        undefined,
        true,
      );
    }
  };

  useEffect(() => {
    if (
      !!candidateData.birthdate &&
      !!candidateData.city_id &&
      !!candidateData.doc_issued &&
      !!candidateData.doc_number &&
      !!candidateData.doc_type &&
      !!file.base64 &&
      !!image.base64 &&
      !!candidateData.email &&
      !!candidateData.names &&
      !!candidateData.last_name &&
      !!candidateData.home_address &&
      !!candidateData.mobile &&
      !!candidateData.occupation &&
      !!candidateData.province_id
    ) {
      setSubDataCandidate({ ...subDataCandidate, btnYes: false });
    } else {
      setSubDataCandidate({ ...subDataCandidate, btnYes: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateData, file, image]);
  useEffect(() => {
    if (candidateData.province_id) {
      dispatch(city_get({ province: candidateData.province_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateData.province_id]);

  const optionSites = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.siteReducer.sites)) {
    counter.siteReducer.sites.forEach(item => {
      optionSites.push({
        value: item.id,
        label: item.description,
        isDisabled: !item.active,
        address: item.address,
      });
    });
  }
  const optionAnnouncement = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.contractReducer.announcement)) {
    counter.contractReducer.announcement.forEach(item => {
      optionAnnouncement.push({
        label: item.title,
        value: item.id,
        name: 'announcement',
      });
    });
  }
  let optionJob_positions = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.contractReducer.job_positions)) {
    counter.contractReducer.job_positions.forEach(item => {
      optionJob_positions.push({
        label: item.job_title,
        value: item.id,
        name: 'job_positions',
        job_description: item.job_description,
      });
    });
  }
  const optionContractTypes = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.contractReducer.contractTypes)) {
    counter.contractReducer.contractTypes.forEach(item => {
      optionContractTypes.push({
        label: item.description,
        value: item.id,
        name: 'contractTypes',
      });
    });
  }

  const optionCandidates = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.contractReducer.candidates)) {
    counter.contractReducer.candidates.forEach(item => {
      optionCandidates.push({
        label: item.full_name,
        value: item.id,
        name: 'candidates',
        doc_number: formatteDocument(item.doc_number).format,
      });
    });
  }
  const createCandidate = () => {
    if (validateEmail(candidateData.email)) {
      if (new Date(new Date() - new Date(candidateData.birthdate)).getFullYear() - 1970 < 15) {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'El candidato debe tener mínimo 15 años',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      } else {
        candidateData.document = [image, file];
        dispatch(
          create_Candidate(
            { ...candidateData, full_name: `${candidateData.last_name} ${candidateData.names}` },
            value => {
              setData({
                ...data,
                candidate: value,
                doc_number: candidateData.doc_number,
                candidateName: `${candidateData.last_name} ${candidateData.names}`
              });
              dispatch(
                getSelectsDirCont({
                  entity_account: counter.loginReducer.currentAccount.id,
                  status: 'verified',
                }),
              );
              setTrigger(trigger + 1);
              cleanModal();
            },
          ),
        );
      }
    } else {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Escriba un correo electrónico valido',
        showCancelButton: false,
      });
    }
  };

  const optionsVacancyType = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.contractReducer.vacancyType)) {
    counter.contractReducer.vacancyType.forEach(item => {
      optionsVacancyType.push({
        label: item.description,
        value: item.id,
        name: 'vacancyType',
      });
    });
  }
  const provincesOptions = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.locationReducer.provinces)) {
    counter.locationReducer.provinces.forEach(item => {
      provincesOptions.push({
        value: item.id,
        label: item.name,
        name: 'id_province',
      });
    });
  }
  const citiesOptions = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.locationReducer.cities)) {
    counter.locationReducer.cities.forEach(item => {
      citiesOptions.push({
        value: item.id,
        label: item.name,
        name: 'id_province',
      });
    });
  }
  const optionsDocuments = [
    {
      value: '',
      label: `Seleccionar...`,
      name: '',
    },
    {
      value: 1,
      label: `C.C`,
      name: 'doc_type',
    },
    {
      value: 2,
      label: `C.E`,
      name: 'doc_type',
    },
    {
      value: 3,
      label: `P.T.T`,
      name: 'doc_type',
    },
    {
      value: 4,
      label: `P.A`,
      name: 'doc_type',
    },
    {
      value: 5,
      label: `NIT`,
      name: 'doc_type',
    },
  ];
  const cleanModal = () => {
    setShowModal(false);
    setimage({
      support_type: 's3_photo',
    });
    setFile({
      support_type: 's3_cv',
    });
    setCandidateData({
      entity_account: counter.loginReducer.currentAccount.id,
      created_by: counter.loginReducer.user_data.id,
    });
  };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>No hay datos</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <>
      {isLoading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      {counter.contractReducer.loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className={bankMoveStyle.centredContainer}>
        <div className={`${bankMoveStyle.container2} mt-4`}>
          <div className={tableStyles.container1}>
            <div className={tableStyles.ItemSmall}>
              <div style={{ alignItems: 'end', marginLeft: '-15px' }} className={`d-flex `}>
                <div style={{ marginBottom: '14px' }}>
                  <img
                    className={` mr-3 hoverPointer`}
                    src={Atras}
                    height='30px'
                    alt=''
                    onClick={
                      !props?.information
                        ? () => goBack()
                        : () =>
                            props.setshowDetail({
                              show: false,
                              isEditing: false,
                            })
                    }
                  />
                </div>
                <div>
                  <span className={` ${tableStyles.titleAdministrative}`}>
                    {props?.information?.candidate ? props.information.candidate : 'Nuevo Contrato'}
                  </span>
                </div>
              </div>
              <label className={Styles.subtitle}>
                {props?.information?.position ? props.information.position : null}
              </label>
            </div>
          </div>
        </div>
        <div className={`pt-3 pb-3`} style={{ paddingLeft: '24px' }}>
          {/* Row 6 */}
          <Row className='d-flex' style={{ width: '100%' }}>
            <div className='mt-4 d-flex justify-content-between'>
              <li className={tableStyles.titleTax}>Empleado</li>

              {props?.information?.candidate ? (
                <div className='d-flex'>
                  {/* /* -------------------------------- PDF Contract Viwer ------------------------------- */}

                  <ModalNew
                    title='Contratos'
                    show={showContract}
                    btnNoName={'Cancelar'}
                    size='700'
                    btnYesDisabled={false}
                    onHide={() => setShowContract(false)}
                    btnNoEvent={() => setShowContract(false)}
                    btnNoDisabled={false}
                  >
                    <PdfViewer
                      downloadable
                      file={counter?.partnersReducer?.urlContract}
                    ></PdfViewer>
                  </ModalNew>
                  {statusFinalized &&
                    props?.information?.contract_typeid !== 4 &&
                    props?.information?.contract_typeid !== 8 &&
                    props?.information?.contract_typeid !== 9 && (
                      <div title='Ver liquidación' className='cursorPointer mr-2'>
                        <img
                          src={settlement}
                          alt='Ver liquidación'
                          onClick={() =>
                            history.push({
                              pathname: '/nomina/liquidacion',
                              state: {
                                statusFinalized: true,
                                endContract: { data: props?.information },
                              },
                            })
                          }
                        />{' '}
                      </div>
                    )}

                  <Tooltip title='Imprimir' className='cursorPointer'>
                    <img src={iconPrint} alt='imprimir' onClick={() => setShowContract(true)} />
                  </Tooltip>
                </div>
              ) : (
                <div className='d-flex justify-content-end'>
                  <div
                    onClick={() => {
                      setShowModal(true);
                      setData({
                        entity_account: counter.loginReducer.currentAccount.id,
                        created_by: counter.loginReducer.user_data.id,
                      });
                      optionJob_positions = [];
                      setTrigger(trigger + 1);
                    }}
                    className={tableStyles.createNomModule}
                  >
                    <b className={`mr-2`}>Crear colaborador</b>
                    <img src={Agregar} alt='User' />
                    <div></div>
                  </div>
                </div>
              )}
            </div>
            <div className='display-grid mt-1 col-8'>
              <label className={tableStyles.crudModalLabel}>Nombre completo{asterisk}</label>
              {props.show ? (
                <SelectComponent
                  isDisabled={props.show}
                  value={
                    props?.information?.cand_id
                      ? {
                          label: props?.information?.candidate,
                        }
                      : optionCandidates.find(x => x.value === data.candidate)
                  }
                  options={optionCandidates}
                  styles={customSelectNewDark}
                  key={'candidate' + trigger}
                  onChange={e => {
                    setData({
                      ...data,
                      candidate: e.value,
                      candidateName: e.label,
                      doc_number: formatteDocument(e.doc_number).value,
                    });
                  }}
                  placeholder={'Seleccionar...'}
                ></SelectComponent>
              ) : isEditing ? (
                <SelectComponent
                  isDisabled={true}
                  value={optionCandidates.find(x => x.value === data.candidate)}
                  options={optionCandidates}
                  styles={customSelectNewDark}
                  key={'candidate' + trigger}
                  onChange={e => {
                    setTrigger(trigger + 1);
                    setData({
                      ...data,
                      candidate: e.value,
                      candidateName: e.label,
                      doc_number: formatteDocument(e.doc_number).value,
                    });
                  }}
                ></SelectComponent>
              ) : (
                <SelectComponent
                  placeholder={'Seleccionar...'}
                  isDisabled={props.show}
                  value={
                    props?.information?.cand_id
                      ? {
                          label: props?.information?.candidate,
                        }
                      : optionCandidates.find(x => x.value === data.candidate)
                  }
                  options={optionCandidates}
                  styles={customSelectNewDark}
                  key={'candidate' + trigger}
                  onChange={e => {
                    setData({
                      ...data,
                      candidate: e.value,
                      candidateName: e.label,
                      doc_number: formatteDocument(e.doc_number).value,
                    });
                  }}
                ></SelectComponent>
              )}
            </div>
            <div className='display-grid mt-1 col-4'>
              <label className={tableStyles.crudModalLabel}>No. documento</label>
              <input
                className={'register-inputs'}
                value={formatteDocument(data.doc_number).format}
                key={'doc_number' + trigger}
                disabled={true}
                type='text'
                name=''
                id=''
              />
            </div>
          </Row>
          <Row className='d-flex' style={{ width: '100%' }}>
            <div className='mt-4'>
              <li className={tableStyles.titleTax}>Cargo</li>
            </div>
            <div
              className={
                data.vacancy_type === 2 || props?.information?.contract_process === 2
                  ? 'display-grid mt-1 col-12'
                  : 'display-grid mt-1 col-12'
              }
            >
              <label className={tableStyles.crudModalLabel}>Seleccionar cargo{asterisk}</label>

              {/* IF DETAIL */}
              {props.show ? (
                <SelectComponent
                  placeholder={'Seleccionar...'}
                  styles={customSelectNewDark}
                  options={optionJob_positions}
                  key={'job_position' + trigger}
                  isDisabled={props.show}
                  value={
                    props?.information?.job_positionid
                      ? {
                          label: props?.information?.position,
                        }
                      : optionJob_positions.find(x => x.value === data.job_position)
                  }
                  onChange={e => {
                    setData({
                      ...data,
                      job_position: e.value,
                      job_description: e.job_description,
                    });
                  }}
                ></SelectComponent>
              ) : isEditing ? (
                <SelectComponent
                  placeholder={'Seleccionar...'}
                  styles={customSelectNewDark}
                  options={optionJob_positions}
                  key={'job_position' + trigger}
                  value={optionJob_positions.filter(x => x.value === data.job_position)}
                  onChange={e => {
                    setTrigger(trigger + 1);
                    setData({
                      ...data,
                      job_position: e.value,
                      job_description: e.job_description,
                    });
                  }}
                ></SelectComponent>
              ) : (
                <SelectComponent
                  placeholder={'Seleccionar...'}
                  styles={customSelectNewDark}
                  options={optionJob_positions}
                  key={'job_position' + trigger}
                  isDisabled={props.show}
                  value={
                    props?.information?.job_positionid
                      ? {
                          label: props?.information?.position,
                        }
                      : optionJob_positions.find(x => x.value === data.job_position)
                  }
                  onChange={e => {
                    setTrigger(trigger + 1);
                    setData({
                      ...data,
                      job_position: e.value,
                      job_description: e.job_description,
                    });
                  }}
                ></SelectComponent>
              )}
            </div>
          </Row>
          {/* Row 2 */}
          <Row className='d-flex' style={{ width: '100%' }}>
            <div className='display-grid mt-1'>
              <label className={tableStyles.crudModalLabel}>Funciones del cargo{asterisk}</label>
              <textarea
                className={`${Styles.textAreaBig} `}
                value={data.job_description}
                key={'job_functions' + trigger}
                disabled={true}
                name='job_functions'
                id=''
                rows='10'
                cols='50'
                style={{ height: '76px', borderColor: '#7FADDE' }}
              ></textarea>
            </div>
          </Row>
          {/* Row 3 */}
          <Row className='d-flex' style={{ width: '100%' }}>
            <div className='mt-4'>
              <li className={tableStyles.titleTax}>Información general</li>
            </div>
            <div className='display-grid mt-1 col-4'>
              <label className={tableStyles.crudModalLabel}>Tipo de contrato{asterisk}</label>

              {/* IF DETAIL */}
              {props.show ? (
                <SelectComponent
                  isDisabled={props.show}
                  value={
                    props?.information?.contract_typeid
                      ? // optionContractTypes.find(x => x.value === props.information.contract_typeid )
                        {
                          label: props?.information?.contract_typename,
                        }
                      : optionContractTypes.find(x => x.value === data.contract_type)
                  }
                  placeholder={'Seleccionar...'}
                  key={'contract_type' + trigger}
                  options={optionContractTypes}
                  styles={customSelectNewDark}
                  onChange={e => {
                    setData({
                      ...data,
                      contract_type: e.value,
                    });
                  }}
                ></SelectComponent>
              ) : isEditing ? (
                <SelectComponent
                  value={optionContractTypes.filter(x => x.value === data.contract_type)}
                  key={'contract_type' + trigger}
                  options={optionContractTypes}
                  styles={customSelectNewDark}
                  onChange={e => {
                    setTrigger(trigger + 1);
                    setData({
                      ...data,
                      contract_type: e.value,
                    });
                  }}
                  placeholder={'Seleccionar...'}
                ></SelectComponent>
              ) : (
                <SelectComponent
                  isDisabled={props.show}
                  value={
                    props?.information?.contract_typeid
                      ? {
                          label: props?.information?.contract_typename,
                        }
                      : optionContractTypes.find(x => x.value === data.contract_type)
                  }
                  placeholder={'Seleccionar...'}
                  key={'contract_type' + trigger}
                  options={optionContractTypes}
                  styles={customSelectNewDark}
                  onChange={e => {
                    setData({
                      ...data,
                      contract_type: e.value,
                    });
                  }}
                ></SelectComponent>
              )}
            </div>
            <div className='display-grid mt-1 col-4'>
              <label className={tableStyles.crudModalLabel}>Fecha{asterisk}</label>
              <input
                className={`register-inputs`}
                type='date'
                value={data.start_date}
                key={'start_date' + trigger}
                disabled={props.show}
                name='start_date'
                max={data.end_date}
                onChange={e => {
                  setData({
                    ...data,
                    start_date: e.target.value,
                  });
                }}
              />
            </div>
            {data.contract_type !== 1 && (
              <div className='display-grid mt-1 col-4'>
                <label className={tableStyles.crudModalLabel}>Vigencia{asterisk}</label>
                <input
                  className={`register-inputs`}
                  type='date'
                  value={data.end_date}
                  key={'end_date' + trigger}
                  disabled={props.show}
                  name='end_date'
                  id=''
                  min={data.start_date}
                  onChange={e => {
                    setData({
                      ...data,
                      end_date: e.target.value,
                    });
                  }}
                />
              </div>
            )}
          </Row>
          {/* Row 4 */}
          <Row className='d-flex' style={{ width: '100%' }}>
            <div className='display-grid mt-1 col-4'>
              <label className={tableStyles.crudModalLabel}>Sede{asterisk}</label>

              {/* IF DETAIL */}
              {props.show ? (
                <SelectComponent
                  placeholder={'Seleccionar...'}
                  options={optionSites}
                  key={'site_name' + trigger}
                  isDisabled={props.show}
                  value={
                    props?.information?.site_id
                      ? {
                          label: props?.information?.site_name,
                        }
                      : optionSites.find(x => x.value === data.site_id)
                  }
                  styles={customSelectNewDark}
                  onChange={e => {
                    setData({
                      ...data,
                      site_id: e.value,
                      site_address: e.address,
                      site_name: e.label,
                    });
                  }}
                ></SelectComponent>
              ) : isEditing ? (
                <SelectComponent
                  placeholder={'Seleccionar...'}
                  options={optionSites}
                  key={'site_name' + trigger}
                  isDisabled={props.show}
                  value={optionSites.filter(x => x.value === data.site_id)}
                  styles={customSelectNewDark}
                  onChange={e => {
                    setData({
                      ...data,
                      site_id: e.value,
                      site_address: e.address,
                      site_name: e.label,
                    });
                  }}
                ></SelectComponent>
              ) : (
                <SelectComponent
                  placeholder={'Seleccionar...'}
                  options={optionSites}
                  key={'site_name' + trigger}
                  isDisabled={props.show}
                  value={
                    props?.information?.site_id
                      ? {
                          label: props?.information?.site_name,
                        }
                      : optionSites.find(x => x.value === data.site_id)
                  }
                  styles={customSelectNewDark}
                  onChange={e => {
                    setData({
                      ...data,
                      site_id: e.value,
                      site_address: e.address,
                      site_name: e.label,
                    });
                  }}
                ></SelectComponent>
              )}
            </div>
            <div className='display-grid mt-1 col-8'>
              <label className={tableStyles.crudModalLabel}>Dirección</label>
              <input
                disabled={true}
                key={'site_address' + trigger}
                value={data.site_address}
                className={'register-inputs'}
                type='text'
                name=''
                id=''
              />
            </div>
          </Row>
          {/* Row 5 */}
          <Row className='d-flex' style={{ width: '100%' }}>
            <div className='display-grid mt-1 col-4'>
              <label className={tableStyles.crudModalLabel}>Salario base{asterisk}</label>
              <NumberFormat
                allowNegative={false}
                style={{ color: '#58595B' }}
                className={'register-inputs'}
                name='valueNote'
                thousandsGroupStyle='thousand'
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'$'}
                disabled={props.show}
                placeholder='$0'
                value={data.base_salary ? data.base_salary : ''}
                key={'base_salary' + trigger}
                onValueChange={values => {
                  const { value } = values;
                  const realValue = parseInt(value) !== 0 ? value : 0;
                  setData({
                    ...data,
                    base_salary: realValue,
                  });
                }}
                min={1}
              />
            </div>
            <div className='display-grid mt-1 col-4'>
              <label className={tableStyles.crudModalLabel}>Ingreso no constitutivo</label>
              <NumberFormat
                allowNegative={false}
                style={{ color: '#58595B' }}
                className={'register-inputs'}
                name='valueNote'
                thousandsGroupStyle='thousand'
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'$'}
                disabled={props.show}
                placeholder='$0'
                value={data.non_const_income ? data.non_const_income : ''}
                key={'non_const_income' + trigger}
                onValueChange={values => {
                  const { value } = values;
                  const realValue = parseInt(value) !== 0 ? value : 0;
                  setData({
                    ...data,
                    non_const_income: realValue,
                  });
                }}
                min={1}
              />
            </div>
            <div className='display-grid col-4' style={{ marginTop: '12px' }}>
              <label className={`${tableStyles.darkBlueText} ${tableStyles.f12} `}>
                Tipo de trabajo
              </label>
              <div className='d-flex gap-1 align-items-center '>
                <Radio
                  key={'8'}
                  checked={data?.modality === 'on_site' ? true : false}
                  onClick={() => {
                    setData({
                      ...data,
                      modality: 'on_site',
                    });
                  }}
                  name='alphabetic'
                  style={{ color: '#005dbf', padding: '0px' }}
                  disabled={props.show}
                />
                <label className={`${tableStyles.darkBlueText} ${tableStyles.f12} `}>
                  Presencial
                </label>
                <Radio
                  key={'9'}
                  checked={data?.modality === 'remote' ? true : false}
                  onClick={() => {
                    setData({
                      ...data,
                      modality: 'remote',
                    });
                  }}
                  name='alphabetic'
                  style={{ color: '#005dbf', padding: '0px' }}
                  disabled={props.show}
                />
                <label className={`${tableStyles.darkBlueText} ${tableStyles.f12}`}>Remoto</label>
              </div>
            </div>
          </Row>
          {props.show ? (
            <></>
          ) : isEditing && !!myPermission.edit ? (
            <Row className={Styles.bottomRow}>
              <button
                className={`col-2 ${tableStyles.btnSecondary}`}
                onClick={
                  props.show
                    ? () => props.cleanDetails()
                    : props.isEditing
                      ? () => props.cleanDetails()
                      : () => goBack()
                }
              >
                Cancelar
              </button>

              <button
                className={`col-2 ${tableStyles.btnPrimary}`}
                onClick={() => contractUpdate()}
              >
                Actualizar contrato
              </button>
            </Row>
          ) : (
            <Row className={Styles.bottomRow}>
              <button
                className={`col-2 ${tableStyles.btnSecondary}`}
                onClick={
                  !props?.information
                    ? () => goBack()
                    : () =>
                        props.setshowDetail({
                          show: false,
                        })
                }
              >
                Cancelar
              </button>
              {!!myPermission?.create && (
                <button
                  className={`col-2 ${tableStyles.btnPrimary}`}
                  onClick={() => contractGenerate()}
                >
                  Generar contrato
                </button>
              )}
            </Row>
          )}
        </div>
      </div>

      <ModalNew
        show={showModal}
        onHide={() => cleanModal()}
        title={'Crear colaborador'}
        btnYesEvent={() => createCandidate()}
        btnYesDisabled={false}
        btnYesName={'Guardar'}
        size={'750'}
      >
        <div className={`${Styles.modaldiv}`}>
          <div className='d-flex'>
            <div className='col'>
              <div className='d-flex'>
                <div className={`display-grid mt-1  col-6 ${Styles.pr_1}`}>
                  <label className={tableStyles.crudModalLabel}>Apellidos{asterisk}</label>
                  <input
                    className={'register-inputs'}
                    type='text'
                    name=''
                    id=''
                    placeholder=''
                    onChange={e => {
                      setCandidateData({
                        ...candidateData,
                        last_name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className='display-grid mt-1 mr-1 col-5'>
                  <label className={tableStyles.crudModalLabel}>Nombres{asterisk}</label>
                  <input
                    className={'register-inputs'}
                    type='text'
                    name=''
                    id=''
                    placeholder=''
                    onChange={e => {
                      setCandidateData({
                        ...candidateData,
                        names: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className='d-flex'>
                <div className={`display-grid mt-1 col-6 ${Styles.pr_1}`}>
                  <label className={tableStyles.crudModalLabel}>Tipo de documento{asterisk}</label>
                  <SelectComponent
                    value={optionsDocuments.filter(x => x.value === candidateData.doc_type)}
                    components={{ NoOptionsMessage }}
                    placeholder=''
                    options={optionsDocuments}
                    styles={customSelectNewDark}
                    onChange={e => {
                      setCandidateData({
                        ...candidateData,
                        doc_type: e.value,
                      });
                    }}
                  ></SelectComponent>
                </div>
                <div className={`display-grid mr-1 mt-1 col-5`}>
                  <label className={tableStyles.crudModalLabel}>No. de documento{asterisk}</label>
                  <input
                    className={'register-inputs'}
                    type='text'
                    name=''
                    id=''
                    value={formatteDocument(candidateData.doc_number).format}
                    onChange={e => {
                      setCandidateData({
                        ...candidateData,
                        doc_number: formatteDocument(e.target.value).value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className='d-flex'>
                <div className={`display-grid mt-1 col-6 ${Styles.pr_1}`}>
                  <label className={tableStyles.crudModalLabel}>
                    Lugar de expedición{asterisk}
                  </label>
                  <input
                    className={'register-inputs'}
                    type='text'
                    name=''
                    id=''
                    onChange={e => {
                      setCandidateData({
                        ...candidateData,
                        doc_issued: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className='display-grid mt-1 mr-1 col-5'>
                  <label className={tableStyles.crudModalLabel}>
                    Fecha de nacimiento{asterisk}
                  </label>
                  <input
                    className={`register-inputs`}
                    type='date'
                    name=''
                    id=''
                    onChange={e => {
                      setCandidateData({
                        ...candidateData,
                        birthdate: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className='d-flex'>
                <div className={`display-grid mr-1 mt-1 col-6 ${Styles.pr_1}`}>
                  <label className={tableStyles.crudModalLabel}>Correo electrónico{asterisk}</label>
                  <input
                    className={'register-inputs'}
                    type='email'
                    name=''
                    id=''
                    onChange={e => {
                      setCandidateData({
                        ...candidateData,
                        email: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className={`display-grid mt-1 mr-1 col-5 `}>
                  <label className={tableStyles.crudModalLabel}>Sexo{asterisk}</label>
                  <SelectComponent
                    className='text-secondary w-100 '
                    noOptionsMessage={() => 'No hay datos'}
                    placeholder={'Seleccionar...'}
                    styles={customSelectNewDark}
                    components={{ NoOptionsMessage }}
                    options={optionsGender}
                    onChange={e => {
                      setCandidateData({
                        ...candidateData,
                        gender: e.value,
                      });
                    }}
                    value={optionsGender.filter(x => x.value === candidateData.gender)}
                  ></SelectComponent>
                </div>
              </div>
            </div>
            <div className='d-flex' style={{ width: '30%', height: '13rem' }}>
              <Card border='#7FADDE' className='w-100 mt-5'>
                {image?.fileUrl ? (
                  <>
                    <img
                      style={{ position: 'absolute' }}
                      className={` hoverPointer  mt-2 mr-2`}
                      src={x}
                      alt='Eliminar'
                      onClick={() => {
                        imageUploadValue.value = '';
                        setimage({
                          filename: '',
                          base64: '',
                          fileUrl: '',
                          support_type: 's3_photo',
                        });
                      }}
                    />
                    <img
                      style={{
                        maxWidth: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      src={image?.fileUrl}
                      alt='imagen usuario '
                    />
                  </>
                ) : (
                  <>
                    <span className='mt-4'></span>
                    <p className={` text-center text-secondary pt-5`}>Insertar imagen</p>
                  </>
                )}
                <label
                  htmlFor='imageUpload'
                  className={`${Styles.imageInputLabel} ${Styles.hoverBlue}`}
                >
                  <img className={`hoverPointer`} src={iconCamera} alt='cambiar imagen' />
                </label>
                <input
                  accept='image/png, image/jpeg'
                  id='imageUpload'
                  name='imageUpload'
                  type='file'
                  onChange={handleImagePick}
                  className={Styles.imageInputBtn}
                />
              </Card>
            </div>
          </div>
          <div className='d-flex'>
            <div className={`display-grid mt-1 col-4 ${Styles.pr_1}`}>
              <label className={tableStyles.crudModalLabel}>Ocupación{asterisk}</label>
              <input
                className={'register-inputs'}
                type='text'
                name=''
                id=''
                onChange={e => {
                  setCandidateData({
                    ...candidateData,
                    occupation: e.target.value,
                  });
                }}
              />
            </div>
            <div className={`display-grid mt-1 col-4 ${Styles.pr_1}`}>
              <label className={tableStyles.crudModalLabel}>Departamento{asterisk}</label>
              <SelectComponent
                value={provincesOptions.filter(x => x.value === candidateData.province_id)}
                components={{ NoOptionsMessage }}
                placeholder='Seleccionar...'
                options={provincesOptions}
                styles={customSelectNewDark}
                onChange={e => {
                  setCandidateData({
                    ...candidateData,
                    province_id: e.value,
                    province_name: e.label,
                    city_id: '',
                    city_name: '',
                  });
                  setTrigger1(trigger1 + 1);
                }}
              ></SelectComponent>
            </div>
            <div className={`display-grid mt-1 col-4 `}>
              <label className={tableStyles.crudModalLabel}>Ciudad{asterisk}</label>
              <SelectComponent
                components={{ NoOptionsMessage }}
                value={citiesOptions.filter(x => x.value === candidateData.city_id)}
                placeholder='Seleccionar...'
                key={'city' + trigger1}
                options={citiesOptions}
                styles={customSelectNewDark}
                onChange={e => {
                  setCandidateData({
                    ...candidateData,
                    city_id: e.value,
                    city_name: e.label,
                  });
                }}
              ></SelectComponent>
            </div>
          </div>
          <div className='d-flex'>
            <div className={`display-grid mt-1 col-12 `}>
              <label className={tableStyles.crudModalLabel}>Dirección de domicilio{asterisk}</label>
              <input
                className={'register-inputs'}
                type='text'
                name=''
                id=''
                onChange={e => {
                  setCandidateData({
                    ...candidateData,
                    home_address: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className={``}>
          <div className='d-flex'>
            <div className={`display-grid mt-1 col-12 `}>
              <label className={tableStyles.crudModalLabel}>Descripción del candidato</label>
              <textarea
                className={Styles.textAreaBig}
                rows='10'
                cols='50'
                onChange={e => {
                  setCandidateData({
                    ...candidateData,
                    description: e.target.value,
                  });
                }}
              ></textarea>
            </div>
          </div>
          <div className='d-flex pb-3'>
            <div className={`display-grid mt-1 col-6 ${Styles.pr_1}`}>
              <label className={tableStyles.crudModalLabel}>Teléfono del contacto{asterisk}</label>
              <input
                className={'register-inputs'}
                type='number'
                name=''
                id=''
                onChange={e => {
                  setCandidateData({
                    ...candidateData,
                    mobile: e.target.value,
                  });
                }}
              />
            </div>
            <div className={`display-grid mt-1 col-6 `}>
              <label className={tableStyles.crudModalLabel}>Hoja de vida{asterisk}</label>
              <label
                className={`${IndividualStyles.registerInputsBlue} hoverPointer d-flex`}
                style={{ backgroundColor: '#fff' }}
                type='text'
                htmlFor='cvUpload'
              >
                {file.filename ? <img src={PdfIcon} alt='' srcSet='' htmlFor='cvUpload' /> : ''}
                <span
                  className={`text-truncate ${tableStyles.crudModalLabel}`}
                  style={{ marginTop: '0.2rem', width: '140px' }}
                >
                  {file.filename}
                </span>
                {file.filename ? (
                  <img
                    src={x}
                    style={{ marginLeft: '5px' }}
                    onClick={() => setFile({ support_type: 's3_cv' })}
                    alt=''
                  />
                ) : (
                  ''
                )}
                <img style={{ marginLeft: 'auto', width: '16px' }} src={adj} alt='' />
                <input
                  className={Styles.imageInputBtn}
                  type='file'
                  accept='application/pdf'
                  name=''
                  id='cvUpload'
                  onChange={handleImagePick}
                />
              </label>
            </div>
          </div>
        </div>
      </ModalNew>
    </>
  );
}
export default CreateContract;
